body {
  font-family: Roboto, sans-serif;
}

:root {
  --ba-light-color: #04daf2;
  --ba-dark-color: #111b25;
  --mdc-theme-primary: var(--ba-dark-color);
  --mdc-theme-secondary: var(--ba-light-color);
  --mdc-theme-error: #d32f2f;
  --mdc-theme-success: #4caf50;
}

.logo-white {
  display: none;
}

.hero {
  width: 100%;
  height: 100vh;
  background-image: url("hero-light.bde7da90.png");
  background-position: center;
  background-size: cover;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.mdc-card, .mdc-dialog, .mdc-dialog__surface, .mdc-card a, .mdc-dialog a, .mdc-dialog__surface a, .mdc-card [class^="mdc-typography-"], .mdc-dialog [class^="mdc-typography-"], .mdc-dialog__surface [class^="mdc-typography-"], .mdc-card .mdc-list-item__text, .mdc-tab:not(.mdc-tab--active), .mdc-data-table__header-cell, .mdc-data-table__cell {
  color: var(--mdc-theme-on-surface);
}

@media (prefers-color-scheme: dark) {
  :root {
    --mdc-theme-primary: var(--ba-light-color);
    --mdc-theme-secondary: var(--ba-dark-color);
    --mdc-theme-background: #202225;
    --mdc-theme-surface: #2f3136;
    --mdc-theme-on-primary: #fff;
    --mdc-theme-on-surface: #fffffff5;
    --mdc-theme-on-background: #fff;
    --mdc-theme-text-primary-on-background: #ffffffe0;
    --mdc-theme-text-secondary-on-background: #fff9;
    --mdc-theme-text-hint-on-background: #fff6;
    --mdc-theme-text-disabled-on-background: #fff6;
    --mdc-theme-text-icon-on-background: #fff6;
  }

  body, .main-content {
    background: var(--mdc-theme-background);
    color: var(--mdc-theme-text-primary-on-background);
  }

  .logo-dark {
    display: none;
  }

  .logo-white {
    display: block;
  }

  .hero {
    background-image: url("hero-dark.6f0d793f.png");
  }

  [class^="mdc-typography-"], .mdc-list-item__text {
    color: var(--mdc-theme-text-primary-on-background);
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-select__anchor, .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: var(--mdc-theme-surface);
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input, .mdc-dialog .mdc-dialog__title {
    color: var(--mdc-theme-text-primary-on-background);
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #fff9;
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text, .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea), .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: #ffffff6b;
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-select__anchor + .mdc-select-helper-text {
    color: #fff9;
  }

  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
    background-color: var(--mdc-theme-primary);
  }

  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: var(--mdc-theme-primary);
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text:hover, .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: var(--mdc-theme-text-primary-on-background);
  }

  .mdc-select:not(.mdc-select--disabled) .mdc-list-item:not(.mdc-list-item--selected) {
    color: #ffffffbf;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: var(--mdc-theme-text-secondary-on-background);
  }

  .mdc-button--raised:disabled, .mdc-button--unelevated:disabled {
    background-color: var(--mdc-theme-surface);
    color: var(--mdc-theme-on-surface);
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: var(--mdc-theme-text-secondary-on-background);
  }

  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: var(--mdc-theme-text-hint-on-background);
  }

  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: var(--mdc-theme-text-primary-on-background);
  }

  .mdc-select__dropdown-icon {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  }

  .mdc-select--focused .mdc-select__dropdown-icon {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%2304DAF2%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  }

  .mdc-linear-progress__buffer {
    background-color: var(--mdc-theme-secondary);
  }
}

.hero-small .hero-content {
  padding: 26px 0;
}

.hero-small .hero-logo-container, .hero-small .hero-secondary-title {
  display: none;
}

.hero-content {
  color: #fff;
  text-align: center;
  padding: 20vh 8px 10vh;
}

.hero-logo-container {
  margin: 0 20px 50px;
  display: inline-block;
}

.hero-logo-container img {
  display: inline-block;
  position: relative;
  left: 0;
  right: 0;
}

.hero-logo-container .hero-secondary-title {
  margin-top: 10px;
}

.hero-buttons {
  margin-top: 30px;
}

.hero-buttons > * {
  margin: 4px 6px;
}

.card-element {
  padding: 16px;
}

.card-title {
  padding: 16px 16px 4px;
}

.row-w-icon {
  align-items: center;
  display: flex;
}

.row-w-icon .material-icons {
  flex: 0;
  margin-right: 6px;
}

.row-w-icon > .div {
  flex: 1;
}

#ba-search-type .mdc-select__anchor, #ba-search-type .mdc-select__menu {
  width: 100%;
}

a {
  word-break: break-word;
  color: var(--mdc-theme-text-primary-on-background);
}

a.unstyled {
  text-decoration: none;
}

.search-form {
  justify-content: space-between;
  gap: 20px 10px;
  display: flex;
}

.search-form .search-input {
  flex: 1;
}

.ban-card {
  background-color: var(--mdc-theme-error);
  color: var(--mdc-theme-text-primary-on-dark);
}

.ban-card a {
  color: var(--mdc-theme-text-primary-on-dark);
}

.ban-card .ban-card-sub:not(:last-child) {
  margin-bottom: 20px;
}

.ban-card .ban-card-sub a {
  color: var(--mdc-theme-on-surface);
}

.mod-note-card a {
  color: var(--mdc-theme-text-primary-on-light);
}

.two-line-box {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.pagination {
  display: flex;
}

.pagination .pagination-page-num {
  text-align: center;
  flex: 1;
  display: inline-block;
}

.cookie-consent {
  z-index: 1;
  background-color: var(--mdc-theme-surface);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-consent h5 {
  margin: 30px 0;
  line-height: 1.1rem;
}

@media (max-width: 800px) {
  .search-form {
    flex-flow: column wrap;
  }

  .search-form > * {
    width: 100%;
  }

  .hero:not(.hero-small) .hero-content {
    padding: 15vh 8px 7vh;
  }
}

@media (min-width: 1400px) {
  .container {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/*# sourceMappingURL=index.4fa98628.css.map */
